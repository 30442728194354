import {
  UserOutlined,
  LoadingOutlined,
  ReloadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Avatar, Tooltip, Button, Menu, Dropdown } from 'antd';
import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { MessageInterface } from '../';

import { useMessage } from '@hooks';
import {
  CounselWindowUserFragment,
  MessageStatus,
  Image as _Image,
  Video as _Video,
} from '@utils/client';
import { timestampFormatter, dateFormatter } from '@utils/formatters';

type MessageRowProps = {
  mine: boolean;
  showAuthor: boolean;
  message: MessageInterface;
  showTimestamp: boolean;
  children: React.ReactNode;
  showDate: boolean;
  users: CounselWindowUserFragment[];
  disable?: boolean;
  counselId: string;
};

const MessageRow: React.FC<MessageRowProps> = ({
  mine,
  showAuthor,
  message,
  users,
  showTimestamp,
  showDate,
  children,
  disable,
  counselId,
}) => {
  const [delayed, setDelayed] = React.useState(false);
  const { removeFailedMessageOnCache, resendTextMessage, deleteMessage } =
    useMessage({ counselId });
  const clipboardInput = useRef<HTMLInputElement | null>(null);
  const handleResendMessage = React.useCallback(() => {
    if (message.__typename === 'TextMessage') {
      resendTextMessage(message.id);
    }
  }, [message, resendTextMessage]);

  const handleCancelMessage = React.useCallback(() => {
    removeFailedMessageOnCache(message.id);
  }, [message.id, removeFailedMessageOnCache]);

  const handleClickDeleteMessage = async () => {
    await deleteMessage(message.id);
  };

  const clipboard = React.useMemo(() => {
    if (message.__typename === 'TextMessage') {
      return (
        <input
          type="text"
          value={message.body}
          ref={clipboardInput}
          style={{ position: 'absolute', left: '-1000px', top: '-1000px' }}
          readOnly
        />
      );
    }
  }, [message]);

  const copyImageToClipboard = async (blob: Blob) => {
    const clipboard = navigator.clipboard;
    if (!clipboard || !clipboard.write) {
      return;
    }
    clipboard.write([
      new ClipboardItem({
        [blob.type]: blob as any,
      }),
    ]);
  };

  const covertImageToPngAndCopyToClipboard = (file: _Image) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    img.src = file.url;
    img.crossOrigin = 'anonymous';
    img.onload = e => {
      if (!e) {
        console.error('이벤트 없음', e);
        return;
      }

      if (!context) {
        console.error('context 생성 실패');
        return;
      }

      if (!e.target) {
        console.error('target 없음', e.target);
        return;
      }
      canvas.width = img.width;
      canvas.height = img.height;
      const target = e.target as CanvasImageSource;
      context.drawImage(target, 0, 0, img.width, img.height);
      canvas.toBlob(
        blob => {
          if (!blob) {
            return;
          }

          copyImageToClipboard(blob);
        },
        'image/png',
        1,
      );
    };
  };

  const handleCopyToClipboard = async () => {
    // TODO: 이후 다른 유형의 메시지도 클립보드복사를 지원할 가능성 있음. 복잡성이 증가하는 경우, 코드 리팩토링
    if (message.__typename === 'TextMessage') {
      const el = clipboardInput.current;
      if (!el) {
        console.error('element 없음');
        return;
      }
      el.select();
      document.execCommand('copy');
    } else if (message.__typename === 'FileMessage') {
      try {
        const clipboard = navigator.clipboard;

        if (!clipboard || !clipboard.write) {
          console.error('clipboard 없음');
          return;
        }
        covertImageToPngAndCopyToClipboard(message.file);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const downloadFile = async () => {
    if (message.__typename === 'FileMessage') {
      const res = await axios.get(message.file.url, {
        responseType: 'blob',
      });
      const splited = message.file.url.split('/');
      const fileName = splited[splited.length - 1];
      fileDownload(res.data, fileName);
    }
  };

  const copiableFileToClipboard = React.useMemo(() => {
    if (message.__typename === 'TextMessage') {
      return true;
    }
    // FileMessage의 경우
    if (message.__typename !== 'FileMessage') {
      return false;
    }

    const file = message.file as _Image | _Video;
    if (file.__typename !== 'Image') {
      return false;
    }

    return true;
  }, [message]);

  const handleMessageMenu = (
    <Menu>
      {copiableFileToClipboard && (
        <Menu.Item onClick={handleCopyToClipboard}>복사</Menu.Item>
      )}
      {message.__typename === 'FileMessage' && (
        <Menu.Item onClick={downloadFile}>저장</Menu.Item>
      )}
      {mine && message.__typename !== 'DeletedMessage' && (
        <Menu.Item key="deleteMessage" onClick={handleClickDeleteMessage}>
          메시지 삭제
        </Menu.Item>
      )}
    </Menu>
  );

  const messageStatusDisplay = React.useMemo(() => {
    if (message.status === MessageStatus.Pending) {
      if (delayed) {
        return (
          <LoadingOutlined style={{ fontSize: '18px', marginRight: '4px' }} />
        );
      }
    } else if (message.status === MessageStatus.Failed) {
      return (
        <SFailActionGroup>
          <SFailAcionButton
            type="primary"
            icon={<ReloadOutlined style={{ fontSize: '12px' }} />}
            danger
            style={{
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
            }}
            onClick={handleResendMessage}
          />
          <SFailActionDivider />
          <SFailAcionButton
            type="primary"
            icon={<CloseOutlined style={{ fontSize: '12px' }} />}
            danger
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
            onClick={handleCancelMessage}
          />
        </SFailActionGroup>
      );
    } else {
      return (
        <Avatar.Group size={16}>
          {users.map(user => (
            <Tooltip key={user.id} title={user.nickname || '(알 수 없음)'}>
              <Avatar
                key={user.id}
                src={user?.avatar?.url}
                icon={<UserOutlined />}
              />
            </Tooltip>
          ))}
        </Avatar.Group>
      );
    }
  }, [
    handleCancelMessage,
    handleResendMessage,
    delayed,
    message.status,
    users,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (message.status === MessageStatus.Pending) {
        setDelayed(true);
      } else {
        setDelayed(false);
        clearTimeout(timeout);
      }
    }, 5000);
  }, [message.status]);

  if (disable) {
    return (
      <>
        {showDate && message.createdAt && (
          <SSign>{dateFormatter(message.createdAt, { withDay: true })}</SSign>
        )}
        {children}
      </>
    );
  }

  return (
    <>
      {clipboard}
      {showDate && message.createdAt && (
        <SSign>{dateFormatter(message.createdAt, { withDay: true })}</SSign>
      )}
      <Container mine={mine} showAuthor={true}>
        {!mine && (
          <SAvatarContainer>
            {showAuthor ? (
              <Avatar
                size="large"
                src={message.author?.avatar?.url || undefined}
                icon={<UserOutlined />}
              />
            ) : (
              <div style={{ width: 40 }} />
            )}
          </SAvatarContainer>
        )}
        <SMessageColumn>
          {!mine && showAuthor && <SName>{message.author?.nickname}</SName>}
          <Dropdown
            overlay={handleMessageMenu}
            trigger={['contextMenu']}
            overlayStyle={{ zIndex: 1081 }}
          >
            <SMessageContainer mine={mine}>
              {children}
              <SExtra mine={mine}>
                {messageStatusDisplay}
                {showTimestamp && message.createdAt && (
                  <STimestamp>
                    {timestampFormatter(message.createdAt)}
                  </STimestamp>
                )}
              </SExtra>
            </SMessageContainer>
          </Dropdown>
        </SMessageColumn>
      </Container>
    </>
  );
};

export default MessageRow;

const SSign = styled.span`
  align-self: center;
  margin: 15px 0px;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 21px;

  border: 1px solid ${props => props.theme.colors.BORDER_PRIMARY_LIGHT};
  color: ${props => props.theme.colors.ON_PRIMARY_LIGHT};
`;

const Container = styled.div<{ mine: boolean; showAuthor: boolean }>`
  display: flex;
  flex-direction: ${props => (props.mine ? 'row-reverse' : 'row')};
  width: 100%;

  margin: 3px 0px;
  ${props =>
    props.showAuthor &&
    css`
      margin-top: 8px;
    `}
`;

const SAvatarContainer = styled.div`
  position: relative;
  margin-right: 8px;
`;

const SMessageColumn = styled.div`
  flex: 1;
  max-width: 70%;
`;

const SName = styled.span`
  margin-bottom: 2px;
`;

const SMessageContainer = styled.div<{ mine: boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.mine ? 'row-reverse' : 'row')};
`;

const SExtra = styled.div<{ mine: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${props => (props.mine ? 'flex-end' : 'flex-start')};
  font-size: 10px;
  margin: 0px 4px;
`;

const SFailActionGroup = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
  width: 37px;
  align-items: center;
`;

const SFailAcionButton = styled(Button)`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

const SFailActionDivider = styled.div`
  flex: 0 0 1px;
  height: 18px;
  border-top: 2px solid #ff4d4f;
  border-bottom: 2px solid #ff4d4f;
`;

const STimestamp = styled.span`
  color: ${props => props.theme.colors.TEXT_MUTED};
`;
